<template>
  <!-- 合同物流 - 查看计划 -->
  <div class="contractLogisticsPage">
    <div class="facilityBox">
      <!-- 搜索表单 -->
      <el-form ref="searchForm" inline :model="searchForm">
        <el-form-item
          label="企业名称"
          prop="enterpriseFullName"
          label-width="80px"
        >
          <el-input
            v-model="searchForm.enterpriseFullName"
            placeholder="请输入企业名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="legalPersonIName" label-width="50px">
          <el-input
            v-model="searchForm.legalPersonIName"
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="手机号"
          prop="enterpriseContactsPhone"
          label-width="65px"
        >
          <el-input
            v-model="searchForm.enterpriseContactsPhone"
            placeholder="请输入手机号码"
          ></el-input>
        </el-form-item>
        <el-button type="primary" icon="el-icon-zoom-in" @click="search"
          >搜索</el-button
        >
        <el-button
          icon="el-icon-delete"
          @click="$refs['searchForm'].resetFields(), search()"
          >清空</el-button
        >
      </el-form>

      <!-- 表格 -->
      <el-table
        :data="partnerPagination.list"
        v-loading="partnerPagination.loading"
        :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
      >
        <el-table-column
          align="center"
          type="index"
          prop="index"
          label="序号"
          width="50"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseFullName"
          label="企业名称"
          width="300"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseAddress"
          label="企业地址"
          width="340"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="LegalPersonIName"
          label="姓名"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="LegalPersonlPhone"
          label="手机号"
          width="120"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="Ycount"
          label="已完成"
          width="160"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.Ycount }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="Jocount"
          label="进行中"
          width="160"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag type="warning">{{ scope.row.Jocount }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="Jjcount"
          label="拒绝"
          width="160"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag type="danger">{{ scope.row.Jjcount }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="Qcount"
          label="取消"
          width="160"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag type="info">{{ scope.row.Qcount }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="160">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="goSceneWaybill(scope.row.UserID)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        background
        style="margin-top: 8px;"
        @current-change="partnerPaginationChange"
        :current-page.sync="partnerPagination.page"
        :page-size="partnerPagination.pagesize"
        :total="partnerPagination.total"
        layout="prev, pager, next, jumper"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { GetPartnerListAsync } from "@/api/contractLogistics/createPlan";
export default {
  data() {
    return {
      searchForm: {
        enterpriseFullName: "", //企业名称
        legalPersonIName: "", //姓名
        enterpriseContactsPhone: "", //企业联系人手机号
      },
      partnerPagination: {
        //合作企业列表
        page: 1,
        pagesize: 10,
        total: 0,
        loading: false,
        list: [],
      },
    };
  },
  methods: {
    //获取合作伙伴列表
    getSecPriseList() {
      this.partnerPagination.loading = true;
      let params = {
        pageSize: this.partnerPagination.pagesize,
        pageIndex: this.partnerPagination.page,
        enterpriseContactsPhone: this.searchForm.enterpriseContactsPhone,
        legalPersonIName: this.searchForm.legalPersonIName,
        enterpriseFullName: this.searchForm.enterpriseFullName,
      };
      GetPartnerListAsync(params)
        .then((res) => {
          this.partnerPagination.list = res.data.DataList.map((item, index) => {
            item.index =
              (this.partnerPagination.page - 1) *
                this.partnerPagination.pagesize +
              index +
              1;
            return item;
          });
          this.partnerPagination.total = Number(res.data.TotalCount);
        })
        .finally(() => {
          this.partnerPagination.loading = false;
        });
    },
    //搜索
    search() {
      this.partnerPagination.page = 1;
      this.getSecPriseList();
    },

    //表格分页改变
    partnerPaginationChange(e) {
      this.partnerPagination.page = e;
      this.getSecPriseList();
    },
    //查看合同物流
    goSceneWaybill(userId) {
      console.log("userId", userId);
      this.$router.push({
        path: "/contractLogistics/plan/planDetail",
        query: {
          userId,
        },
      });
    },
  },
  created() {
    this.getSecPriseList();
  },
};
</script>

<style lang="scss" scoped></style>
